import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "2024 Nasarawa Investment Report",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {
      
      filesrcs: [
        // 27/02/2025
        { title: '2024 Nasarawa Investment Report', src: 'https://api.nasida.na.gov.ng/services/NASIDA/27-02-2025/2024 Report of Investments in Nasarawa.pdf', time:'Feb 27, 2025' },
      ],
    }
  }, 

  methods: {
    goto(url) {
      window.open(url);
    },
  },


});
